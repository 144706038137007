import React, { useState } from 'react'

import {
  Text,
  Input,
  InputGroup,
  InputLeftElement,
  Flex,
  OrderedList,
  ListItem,
  Button,
  InputRightElement,
  List,
  PinInput,
  PinInputField
} from '@chakra-ui/react'

import {
  SearchIcon
} from '@chakra-ui/icons'
import { Link } from 'react-router-dom'

import axios from 'axios'

const Train = () => {
  const [success, setSuccess] = useState(false)

  const callback = (e) => {
    setSuccess(e)
  }
  return (
    <>
      <Flex w='100%' align='center' justifyContent='center' h='100vh' bg='blue.900'>
        {success
          ? <Display callback={(e) => callback(e)} />
          : <AccessCode callback={(e) => callback(e)} />}
      </Flex>
    </>
  )
}

const AccessCode = (props) => {
  const [access, setAccess] = useState('')

  const handleSubmit = () => {
    if (access.length > 1) {
      if (access === '2437') {
        props.callback(true)
      } else {
        props.callback(false)
      }
    }
  }
  return (
    <>
      <PinInput mask onChange={(e) => setAccess(e)} value={access}>
        <PinInputField color='white' />
        <PinInputField color='white' />
        <PinInputField color='white' />
        <PinInputField color='white' />
      </PinInput>
      <Button onClick={handleSubmit} ml='2%'>Submit</Button>
    </>
  )
}

const Display = (props) => {
  const [results, setResults] = useState([])
  const [topic, setTopic] = useState('')

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (topic.length > 1) {
      await axios.post('https://businessventures.herokuapp.com/api/generate/', {
        topic
      })
        .then(res => {
          setResults(res.data.arr)
        })
    }
  }

  const handleAdd = async () => {
    let responses = ''
    for (var i = 0; i < results.length; i++) {
      responses += results[i]
      responses += "\n"
    }
    await axios.post('https://businessventures.herokuapp.com/api/generate/addprompt', {
      instructions: `create 5 business ideas about ${topic}`,
      responses
    })
  }

  return (
    <>
      <Flex align='center' justifyContent='center' flexDir='column'>
        <Text fontWeight='300' textAlign='center' color='white' fontSize={50}>Generate Business Ideas</Text>
        <form onSubmit={handleSubmit}>
          <InputGroup mt='5%'>
            <InputLeftElement
              pointerEvents="none"
              children={<SearchIcon color="gray.300" />}
            />
            <Input value={topic} onChange={(e) => setTopic(e.target.value)} w={[300, 300, 400, 500]} color='white' variant="flushed" type="text" placeholder="Enter topic (ex: music)" />
            <InputRightElement width="4.5rem">
              <Button variant='ghost' colorScheme='cyan' h="1.75rem" size="sm" onClick={handleSubmit}>
                Submit
              </Button>
            </InputRightElement>
          </InputGroup>
        </form>
        <Flex align='center' justifyContent='center' w='50%' mt='1%' >
          {results.length > 1
            ? <Button onClick={handleAdd}>Add</Button>
            : null}
          <Button onClick={() => props.callback(false)} ml='5%'>Logout</Button>
        </Flex>
        <List w='80%' align='center'>
          {results.map((data, index) => (
            <>
              <ListItem mt='5%' fontWeight='200' fontSize={[20, 25, 25, 30]} color='white'>{data}</ListItem>
            </>
          ))}
        </List>
        <Text pos='absolute' bottom='5' fontWeight='200' fontSize={25} color='white' >&copy; 2021 {" "}
          <Link href='#' color='teal.400'>Elias Wambugu</Link>
        </Text>
      </Flex>
    </>
  )
}

export default Train