import React, { useState } from 'react'

import {
  Text,
  Input,
  InputGroup,
  InputLeftElement,
  Flex,
  OrderedList,
  ListItem,
  Button,
  InputRightElement,
  List
} from '@chakra-ui/react'

import { useHistory } from 'react-router-dom'

import {
  SearchIcon
} from '@chakra-ui/icons'
import { Link } from 'react-router-dom'

import axios from 'axios'

const Home = () => {
  const history = useHistory()
  const [results, setResults] = useState([])
  const [topic, setTopic] = useState('')

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (topic.length > 1) {
      await axios.post('https://businessventures.herokuapp.com/api/generate/', {
        topic
      })
        .then(res => {
          setResults(res.data.arr)
        })
    }
  }

  return (
    <>
      <Flex w='100%' flexDir='column' align='center' justifyContent='center' h='100vh' bg='blue.900'>
        <Button top='5%' right='5%' pos='absolute' onClick={() => history.push('/train')}>Train</Button>
        <Text fontWeight='300' textAlign='center' color='white' fontSize={50}>Generate Business Ideas</Text>
        <form onSubmit={handleSubmit}>
          <InputGroup mt='5%'>
            <InputLeftElement
              pointerEvents="none"
              children={<SearchIcon color="gray.300" />}
            />
            <Input value={topic} onChange={(e) => setTopic(e.target.value)} w={[300, 300, 400, 500]} color='white' variant="flushed" type="text" placeholder="Enter topic (ex: music)" />
            <InputRightElement width="4.5rem">
              <Button variant='ghost' colorScheme='cyan' h="1.75rem" size="sm" onClick={handleSubmit}>
                Submit
              </Button>
            </InputRightElement>
          </InputGroup>
        </form>
        <List w='80%' align='center'>
          {results.map((data, index) => (
            <>
              <ListItem mt='5%' fontWeight='200' fontSize={[20, 25, 25, 30]} color='white'>{data}</ListItem>
            </>
          ))}
        </List>
        <Text pos='absolute' bottom='5' fontWeight='200' fontSize={25} color='white' >&copy; 2021 {" "}
          <Link href='#' color='teal.400'>Elias Wambugu</Link>
        </Text>
      </Flex>
    </>
  )
}

export default Home