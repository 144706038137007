import React from 'react'

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import Home from './Pages/Home'
import Train from './Pages/Train'

const App = () => {
  return (
    <>
      <Router>
        <Switch>
          <Route exact path="/" render={(props) => <Home {...props} />} />
          <Route exact path="/train" render={(props) => <Train {...props} />} />
        </Switch>
      </Router>
    </>
  )
}

export default App